import qs from 'qs';

import type { ProductCardProps } from '@coursera/cds-core';

import GrowthDiscoveryExperiments from 'bundles/epic/clients/GrowthDiscovery';
import {
  ALGOLIA_PROD_RESULTS_INDEX,
  DEFAULT_SORTED_VALUE,
  INDEX_TYPE_TO_NAME_EXPERIMENT_PARAMETER_NAME,
  OLD_TO_NEW_PARAM_MAP,
  ON_SITE_SEARCH_PATH,
  SEARCH_MAIN_INDEX_ID,
  VALID_SORTED_VALUES,
} from 'bundles/search-common/constants';
import type {
  SearchFacet,
  SearchHit,
  SearchProductHit,
  SearchResultRaw,
  SearchStates,
  SearchSuggestionHit,
} from 'bundles/search-common/providers/searchTypes';
import type {
  HighlightItem,
  SearchFilterOption,
  SearchIndexHit,
  SearchIndexHitLearningProduct,
  SearchSortType,
} from 'bundles/search-common/types';

import _t from 'i18n!nls/search-common';

// These functions are used to set up the algolia indices, used in the search-v2 bundle.
// To create an A/B test on search-v2 you can follow this doc: https://docs.google.com/document/d/1qSlMKdRYNXlBkYfK2cnvojJtHgKNcgYT_1XX10MX7PQ/edit#heading=h.g2b8nfa3vx9r
export const getIndexTypeToNameMap = () => GrowthDiscoveryExperiments.get(INDEX_TYPE_TO_NAME_EXPERIMENT_PARAMETER_NAME);
export const getAllTabIndexName = () => getIndexTypeToNameMap()?.all ?? ALGOLIA_PROD_RESULTS_INDEX;

export const formatNumberForLocale = (numOfResults: number) => {
  const userLocale = _t.getLocale();
  return numOfResults.toLocaleString(userLocale);
};

export const getPartnersFromHit = (hit: SearchIndexHit): ProductCardProps['partners'] =>
  hit?.partners?.map((partnerName, index) => ({
    name: partnerName ?? '',
    logoUrl: hit?.partnerLogos?.[index] ?? '',
  })) ?? [];

export const createSeoURL = (query: string, index: string, page: number, activeFilters?: string[]) => {
  const queryParameters: Record<string, number | string | string[] | undefined> = {};
  queryParameters.query = query ?? undefined;
  const hasActiveFilters = (activeFilters?.length || 0) > 0;
  const isDefaultRoute = !query && page && page === 1 && hasActiveFilters;

  // we don't need filter SEARCH_FILTERS_FOR_URL_SYNC because activeFilters already filtered

  if (!isDefaultRoute) {
    if (page > 1) {
      queryParameters.page = page;
    }

    if (hasActiveFilters) {
      // allows for multiple filters in same subcategory to be added to the pagnination url
      for (const filter of activeFilters ?? []) {
        const [category, label] = filter.split(':');

        if (typeof queryParameters === 'object' && queryParameters[category]) {
          if (Array.isArray(queryParameters[category])) {
            (queryParameters[category] as string[]).push(label);
          } else {
            queryParameters[category] = [label];
          }
        }
      }
    }
  }

  // Replaces encoded spaces to the plus symbol to increase URL readability
  const queryString = qs.stringify(queryParameters, { arrayFormat: 'repeat' });

  return queryString;
};

export function findKeywordIndices(sentence: string, keyword: string): { start: number; end: number } {
  const index = sentence.toLowerCase().indexOf(keyword.toLowerCase());
  return { start: index, end: index !== -1 ? index + keyword.length : index };
}

export function splitSentenceIntoObjects(
  sentence: string,
  isHighlighted: boolean
): { value: string; isHighlighted: boolean }[] {
  if (!sentence.length) return [];
  const words = sentence.split(/\b/);
  const results = [];

  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    if (word === ' ') {
      results.push({ value: ' ', isHighlighted: false });
    } else {
      results.push({ value: word, isHighlighted });
    }
  }

  return results;
}

export function highlightKeywords(sentence: string, keyword: string): HighlightItem[] {
  let results: HighlightItem[] = [];
  const { start, end } = findKeywordIndices(sentence, keyword) || {};

  if (start !== -1) {
    if (start !== 0) {
      results = splitSentenceIntoObjects(sentence.slice(0, start), false);
    }
    results = [...results, ...splitSentenceIntoObjects(sentence.slice(start, end), true)];
    if (end < sentence.length) {
      results = [...results, ...splitSentenceIntoObjects(sentence.slice(end, sentence.length), false)];
    }
    return results;
  } else {
    return splitSentenceIntoObjects(sentence, false);
  }
}

export const mapSuggestionHitToHighlightItem = (
  elements?: SearchSuggestionHit[],
  query?: string
): SearchIndexHitLearningProduct[] | undefined => {
  return elements?.map((suggestion) => ({
    ...suggestion,
    id: suggestion.id || undefined,
    name: suggestion.name || undefined,
    highlightResult: highlightKeywords(suggestion.name || '', query || ''),
    productDifficultyLevel: undefined,
    productDuration: undefined,
  }));
};

export const getUserDefinedFacetFilters = (facetFilters?: string[][]): string[][] | undefined =>
  facetFilters
    ?.map((filters) => {
      const userDefinedFilters = filters.filter((f) => !f.includes(':-'));
      if (userDefinedFilters.length === 0) return undefined;
      return userDefinedFilters;
    })
    .filter((f) => !!f) as string[][];

export const getActiveFilters = (facetFilters?: string[][]): Record<string, string[]> => {
  const activeFilters: Record<string, Array<string>> = {};
  if (facetFilters) {
    facetFilters.forEach((innerArray) => {
      innerArray.forEach((string) => {
        const [key, value] = string.split(':');
        if (!value) return;
        if (key in activeFilters) {
          activeFilters[key].push(value);
        } else {
          activeFilters[key] = [value];
        }
      });
    });
  }
  return activeFilters;
};

export const getAllFacets = (
  allIndexFacets: SearchFacet[] | null | undefined,
  adjustedFacets: SearchFacet[],
  activeFilters: Record<string, Array<string>>
) => {
  const facetsMap: Record<string, SearchFacet> = {};

  // Store all facets from allIndexFacets in the map
  allIndexFacets?.forEach((facet) => {
    if (facet.name) {
      facetsMap[facet.name] = facet;
    }
  });

  // Overwrite facets with adjustedFacets based on the name
  if (adjustedFacets.length > 0) {
    adjustedFacets.forEach((adjustedFacet) => {
      if (adjustedFacet.name) {
        const existingFacet = facetsMap[adjustedFacet.name];
        if (existingFacet) {
          // Facet with the same name exists, overwrite its valuesAndCounts
          facetsMap[adjustedFacet.name] = adjustedFacet;
        } else {
          // Facet doesn't exist, add it to the map
          facetsMap[adjustedFacet.name] = adjustedFacet;
        }
      }
    });
  }

  const facetArray = Object.values(facetsMap);
  facetArray.forEach((facet, index) => {
    const facetName = facet.name;
    const facetValues = facet.valuesAndCounts;
    if (facetName in activeFilters) {
      const activeFilterValues = activeFilters[facetName];
      const excludeFilters = activeFilterValues
        .filter((item) => item?.indexOf('-') === 0)
        .map((s) => s.replace('-', ''));
      const valuesAndCounts = facetValues.slice(); // make a copy
      // remove active "exclude" filters (usually defined in config by dev) from UI
      const updatedValuesAndCounts = valuesAndCounts.filter((item) => !excludeFilters.includes(item.value));

      activeFilterValues.forEach((filterValue) => {
        const matchingValue = facetValues.find((value) => value.value === filterValue);
        if (!matchingValue) {
          updatedValuesAndCounts.push({ __typename: 'Search_FacetValueAndCount', count: 0, value: filterValue });
        }
      });
      facetArray[index] = {
        ...facet,
        valuesAndCounts: updatedValuesAndCounts,
      };
    }
  });

  // Convert the map values back to an array and return it

  return facetArray;
};

export const consolidateFilters = (
  searchStates: SearchStates,
  filterResults: SearchResultRaw[],
  rawResults: SearchResultRaw[],
  filters?: string[]
) => {
  const filtersIndex = rawResults.length - 1;
  const allIndexFilters = rawResults[filtersIndex];

  const activeFilters = getActiveFilters(searchStates[SEARCH_MAIN_INDEX_ID]?.facetFilters);

  const newArrayFacets: SearchFacet[] = [];

  filters?.forEach((filterName, index) => {
    allIndexFilters.facets?.forEach((facet) => {
      if (facet.name === filterName && filterResults && filterResults[index]) {
        filterResults[index].facets?.forEach((filterFacet) => {
          if (filterFacet.name === filterName) {
            newArrayFacets.push(filterFacet);
          }
        });
      }
    });
  });
  const allFacets = getAllFacets(allIndexFilters.facets, newArrayFacets, activeFilters);
  const properlyFiltered = { ...allIndexFilters, facets: allFacets };

  return properlyFiltered;
};

export const getDefaultSortByValue = (defaultSortby: SearchSortType) => {
  if (defaultSortby !== undefined && VALID_SORTED_VALUES.includes(defaultSortby)) {
    return defaultSortby;
  }
  return DEFAULT_SORTED_VALUE;
};

export const getQueryParamsString = (queryParams: Record<string, unknown>) => {
  let queryParamsString = '';
  for (const key in queryParams) {
    const value = queryParams[key];

    if (Array.isArray(value)) {
      value.forEach((item) => {
        queryParamsString += `${key}=${item}&`;
      });
    } else {
      queryParamsString += `${key}=${value}&`;
    }
  }
  queryParamsString = queryParamsString.slice(0, -1);
  return queryParamsString;
};

export function deconstructFilter(filter: string) {
  const colonIndex = filter.indexOf(':');
  const facetName = filter.slice(0, colonIndex);
  const facetValue = filter.slice(colonIndex + 1);
  return { facetName, facetValue };
}

export const scrollUpIfNecessary = (options?: {
  prefersReducedMotion?: boolean;
  disableScrollAnimation?: boolean;
  mustBeScrolledPastThisElementId?: string;
}) => {
  const isPrefersReducedMotion =
    options?.prefersReducedMotion && !!window?.matchMedia(`(prefers-reduced-motion: reduce)`)?.matches === true;
  const distanceToTargetTop =
    document?.getElementById(options?.mustBeScrolledPastThisElementId ?? '')?.getBoundingClientRect()?.top ?? 0;
  // Reduced motion scroll (i.e. instant) only works with setTimeout
  setTimeout(() => {
    // only scroll up if our target is not already in the viewport
    if (distanceToTargetTop < 0) {
      window.scrollTo({
        top: 0,
        left: 0,
        // Use 'auto' behavior if prefersReducedMotion is true or disableScrollAnimation is true
        behavior: isPrefersReducedMotion || options?.disableScrollAnimation ? 'auto' : 'smooth',
      });
    }
  }, 0);
};

export const getEnterpriseAutocompleteSectionTitle = (productType: string) => {
  if (productType === 'VIDEO') {
    return _t('Videos');
  } else {
    return _t('Courses & Specializations');
  }
};

export const isSearchProductHit = (hit: SearchHit): hit is SearchProductHit => hit.__typename === 'Search_ProductHit';

export function isSearchSuggestionHit(hit: SearchHit): hit is SearchSuggestionHit {
  return hit.__typename === 'Search_SuggestionHit';
}

export function hasLegacyQueryParams(queryParams: Record<string, unknown>) {
  return Object.keys(queryParams).some((key: string) => Boolean(OLD_TO_NEW_PARAM_MAP[key]));
}

export function getSearchUrlFromParams(queryParams: Record<string, unknown>): string {
  const newQueryParams = Object.entries(queryParams).reduce((acc: Record<string, unknown>, [key, value]) => {
    acc[OLD_TO_NEW_PARAM_MAP[key] ?? key] = encodeURIComponent(String(value));
    return acc;
  }, {});

  return `${ON_SITE_SEARCH_PATH}?${getQueryParamsString(newQueryParams)}`;
}

export const shouldShowSuggestedSearches = (
  query?: string,
  suggestedSearches?: SearchIndexHitLearningProduct[] | SearchHit[]
) => {
  const isQueryNotEmpty = query && query.trim() !== '';
  // if there's only one suggestion and it's the same as the current search's query, we don't want to show suggestions for UX purposes
  const areThereValidSuggestions = suggestedSearches && suggestedSearches.length > 0;
  return areThereValidSuggestions && isQueryNotEmpty;
};

export const createFilterKeyToOptionsMap = (
  facets: SearchFacet[] | null | undefined,
  facetFilters: string[][] | undefined,
  filterListOrder: string[],
  allFilters: SearchFacet[] | null | undefined
) => {
  const filterKeyToValueMap: Record<string, SearchFilterOption[] | undefined> = {};

  facets?.forEach(({ name, valuesAndCounts }) => {
    filterKeyToValueMap[name] =
      valuesAndCounts?.map((filter) => ({
        ...filter,
        label: filter.value,
        isRefined: facetFilters?.flat().includes(`${name}:${filter.value}`) ?? false,
      })) || [];
  });
  filterListOrder.forEach((filter) => {
    const isActive = facetFilters?.flat().some((str) => str.includes(filter));
    if (isActive) {
      allFilters?.forEach(({ name, valuesAndCounts }) => {
        if (name === filter) {
          filterKeyToValueMap[name] =
            valuesAndCounts?.map((_filter) => ({
              ..._filter,
              label: _filter.value,
              isRefined: facetFilters?.flat().includes(`${name}:${_filter.value}`) ?? false,
            })) || [];
        }
      });
    }
  });
  return filterKeyToValueMap;
};

export const removeWhiteSpace = (str: string) => str?.replace(/\s+/g, ' ') ?? '';
