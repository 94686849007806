import * as Sentry from '@sentry/react';

import type DeviceProvider from './device.provider';
import { createDefaultDeviceProvider } from './device.provider';
import type { onEvents } from './emitter';
import Emitter from './emitter';
import Envelope from './envelope';
import type Events from './events';
import type SessionProvider from './session.provider';
import { createDefaultSessionProvider } from './session.provider';
import { LoggerTransport, createDefaultTransport } from './transport';
import type { Transport } from './transport';

type Configuration = {
  batchUrl?: string;
  url?: string;
};

type Middleware = <K extends keyof Events>(eventName: K, data: Events[K]) => Events[K] | Promise<Events[K]>;

type Options = Configuration &
  onEvents & {
    debug?: boolean;
    device?: DeviceProvider;
    session?: SessionProvider;
    transport?: Transport;
    type?: 'web' | 'server';
  };

type Plugin = (client: Client) => void;

declare global {
  interface Window {
    appName?: string;
  }
}

/**
 * Client that sends events to the server.
 */
class Client extends Emitter<onEvents> {
  /**
   * The device that sends the event.
   */
  readonly device: DeviceProvider;

  /**
   * The session that sends the event.
   */
  readonly session: SessionProvider;

  /**
   * The transport that sends the event.
   * @private
   */
  private readonly transport: Transport;

  /**
   * The type of the client. We could use this client in a different environment.
   */
  readonly type: 'web' | 'server';

  /**
   * The version of the client.
   */
  readonly version = '2.1.0';

  private middlewares: Middleware[] = [];

  constructor(options: Options = {}) {
    super();

    const { debug = false } = options;

    this.device = options.device ?? createDefaultDeviceProvider();

    this.session = options.session ?? createDefaultSessionProvider();

    this.transport = debug
      ? new LoggerTransport()
      : options.transport ??
        createDefaultTransport({
          batchUrl: options.batchUrl,
          url: options.url,
        });

    this.type = options.type ?? 'web';
  }

  /**
   * Send an event
   *
   * @param eventName - The name of the event
   * @param input - The data associated with the event
   */
  async sendEvent<K extends keyof Events>(eventName: K, input: Events[K]): Promise<void> {
    // Create a new session if the current one is expired
    if (this.session.get().isExpired()) {
      // Reset the session
      this.session.reset();

      // Session has started
      this.trigger('sessionStart', this.session.get());
    }

    this.session.touch();
    this.device.touch();

    // Reduce async middlewares
    const data = await this.middlewares.reduce<Promise<Events[K]>>(
      async (acc, middleware) => middleware(eventName, await acc),
      Promise.resolve(input)
    );

    this.trigger('beforeSend', eventName, data);

    // Wrap the data in an envelope, it extracts the relevant information
    const envelope = new Envelope({
      app: window.appName ?? 'web',
      client: this,
      device: this.device.get(),
      key: eventName,
      session: this.session.get(),
      value: data,
    });

    try {
      await this.transport.send(envelope);
      // If ^ fails, we just give up and will see the report on Sentry
      // Most of the transport have a retry mechanism, or do not throw
    } catch (e) {
      // We don't want to throw an error
      Sentry.captureException(e);
    }

    this.trigger('sent', eventName, data);
  }

  middleware(callback: Middleware) {
    this.middlewares.push(callback);
  }

  register(plugin: Plugin) {
    plugin(this);
  }
}

export type { Plugin };

export default Client;
